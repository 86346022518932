<template>
  <b-container fluid class="p-0">
    <b-form-group label="Стоимость:">
      <b-table
        class="datatable"
        :items="appGroupPrices"
        :fields="pricesColumns"
        :busy="loading"
      >
        <template #cell()="data">
          <div :id="`${data.field.key}_${data.index}_tooltip`">{{data.value}}</div>

          <b-tooltip
            v-if="data.item[`${data.field.key}_tooltip`] && data.item[`${data.field.key}_tooltip`].length"
            :target="`${data.field.key}_${data.index}_tooltip`"
          >
            <div
              class="mb-1"
              v-for="(text, index) in data.item[`${data.field.key}_tooltip`]"
              :key="index"
            >
              <div>{{ text }}</div>
            </div>
          </b-tooltip>
        </template>
      </b-table>
    </b-form-group>

    <b-form-group
      label="Динамическое ценообразование:"
      :invalid-feedback="errors.minPrice"
      :state="states.minPrice"
    >
      <b-form-checkbox
        :checked="item.disablePriceRate"
        :state="states.disablePriceRate"
        :disabled="loading"
        @change="updateValue('disablePriceRate', !item.disablePriceRate)"
      >
        Отключить динамическое ценообразование для сеанса
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      label="Минимальная стоимость:"
      :invalid-feedback="errors.minPrice"
      :state="states.minPrice"
    >
      <b-form-input
        type="text"
        size="sm"
        :value="item.minPrice"
        :state="states.minPrice"
        :disabled="loading"
        @change="updateValue('minPrice', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Формула изменения стоимости:"
      :invalid-feedback="errors.price"
      :state="states.price"
    >
      <price-formula
        :value="item.priceFormula || ''"
        :operations="['-', '+', '=']"
        :disabled="loading"
        :resetable="true"
        @change="updateValue('priceFormula', $event)"
      ></price-formula>
    </b-form-group>

    <b-form-group label="Статус продаж для группы приложений:">
      <show-settings-table
        :fields="datatable.fields"
        :items="appGroups"
        :loading="loading"
      >
        <template #head(itemChecked)>
          <b-form-checkbox
            :checked="allAppGroupChecked"
            :disabled="appGroupsDisabled"
            @click.native.stop
            @change="toggleAppGroupAll()"
          ></b-form-checkbox>
        </template>

        <template #cell(itemChecked)="data">
          <b-form-checkbox
            :checked="isAppGroupChecked(data.item)"
            :disabled="isAppGroupClosingDisabled(data.item)"
            @click.native.stop
            @change="toggleAppGroup(data.item)"
          ></b-form-checkbox>
        </template>

        <template #cell(status)="data">
          <span :class="[ isAppGroupChecked(data.item) ? 'text-success' : 'text-danger' ]">
            {{ isAppGroupChecked(data.item) ? 'Открыты' : 'Закрыты' }}
          </span>
        </template>
      </show-settings-table>
    </b-form-group>

    <b-form-group label="Скидки:">
      <div v-if="!appGroupDiscounts.length">Нет настроенных скидок</div>
      <show-settings-table
        v-else
        :fields="datatable.fieldsDicounts"
        :items="appGroupDiscounts"
        :loading="loading"
      >
        <template #head(itemChecked)>
          <b-form-checkbox
            :checked="allDiscountChecked"
            @click.native.stop
            @change="toggleDiscountAll()"
          ></b-form-checkbox>
        </template>

        <template #cell(itemChecked)="data">
          <b-form-checkbox
            :checked="isDiscountChecked(data.item)"
            @click.native.stop
            @change="toggleDiscount(data.item)"
          ></b-form-checkbox>
        </template>

        <template #cell(appGroups)="data">
          {{ $array.convertToText(data.item.appGroups) }}
        </template>

        <template #cell(status)="data">
          <span :class="[ isDiscountChecked(data.item) ? 'text-success' : 'text-danger' ]">
            {{ isDiscountChecked(data.item) ? 'Да' : 'Нет' }}
          </span>
        </template>
      </show-settings-table>
    </b-form-group>
  </b-container>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import { mapState, mapActions } from 'vuex';

import fields from './_index.fields';
import fieldsDicounts from './_index.fields.discounts';

import ShowSettingsTable from './ShowSettingsTable.vue';
import PriceFormula from '../../components/fields/PriceFormula.vue';

export default {
  components: {
    ShowSettingsTable,
    PriceFormula,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      datatable: {
        fields,
        fieldsDicounts,
      },
      appGroupsDisabled: false,
      recentlyChangedAppGroupStatus: [],
    };
  },
  computed: {
    ...mapState('dictionaries/promotions', {
      promotions: (state) => state.items,
      promotionsLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/appGroups', {
      appGroups: (state) => state.items,
      appGroupsLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/colorSchemes', {
      colorSchemes: (state) => state.items,
      colorSchemesLoading: (state) => state.loading,
    }),

    pricesColumns() {
      let colorSchemes = [];
      let columns = [];

      if (!this.item.appGroupPrices) {
        return columns;
      }

      for (const appGroupPrices of Object.values(this.item.appGroupPrices)) {
        colorSchemes = appGroupPrices.reduce((_colorSchemes, price) => {
          if (!_colorSchemes.includes(price.colorSchemeId)) {
            _colorSchemes.push(price.colorSchemeId);
          }

          return _colorSchemes;
        }, colorSchemes);
      }

      columns = [
        {
          id: 'id',
          label: '',
          class: 'app-group-id',
        },
        {
          key: 'name',
          label: 'Приложения',
          class: 'app-group-name',
        },
        ...colorSchemes.map((colorSchemeId) => ({
          key: `color_scheme_${colorSchemeId}`,
          label: this.colorSchemes.find((cs) => cs.id === colorSchemeId)?.name,
          class: 'color-scheme-price',
        })),
      ];

      return columns;
    },

    appGroupPrices() {
      const prices = [];

      if (!this.item.appGroupPrices) {
        return prices;
      }

      for (const [appGroupId, appGroupPrices] of Object.entries(this.item.appGroupPrices)) {
        const appGroup = this.appGroups.find((ag) => ag.id === +appGroupId);

        const price = {
          name: appGroup?.name,
        };

        for (const colorSchemePrice of appGroupPrices) {
          let promotionName = '';

          if (colorSchemePrice.promotionId) {
            promotionName = this.promotions.find((p) => p.id === colorSchemePrice.promotionId)?.name;
          }

          price[`color_scheme_${colorSchemePrice.colorSchemeId}`] = promotionName ? `${colorSchemePrice.price}₽ (${promotionName})` : `${colorSchemePrice.price}₽`;

          const tooltip = [];

          if (this.item.appGroupPricesCalculation && this.item.appGroupPricesCalculation[appGroupId]) {
            const {
              pricesBase, pricesWithRate, pricesWithPromotion, pricesWithFormula,
            } = this.item.appGroupPricesCalculation[appGroupId];
            const priceBase = pricesBase.find(
              (p) => p.colorSchemeId === colorSchemePrice.colorSchemeId,
            );

            tooltip.push(`Базовая стоимость: ${priceBase.price}₽`);

            const priceWithRate = pricesWithRate.find(
              (p) => p.colorSchemeId === colorSchemePrice.colorSchemeId,
            );

            if (priceWithRate.price !== priceBase.price) {
              tooltip.push(`Динамическая цена: ${priceWithRate.price}₽`);
            }

            const priceWithPromotion = pricesWithPromotion.find(
              (p) => p.colorSchemeId === colorSchemePrice.colorSchemeId,
            );

            if (priceWithPromotion.price !== priceWithRate.price) {
              tooltip.push(`По акции ${promotionName}: ${priceWithPromotion.price}₽`);
            }

            const priceWithFormula = pricesWithFormula.find(
              (p) => p.colorSchemeId === colorSchemePrice.colorSchemeId,
            );

            if (priceWithFormula.price !== priceWithPromotion.price) {
              tooltip.push(`По формуле ${this.item.priceFormula}: ${priceWithFormula.price}₽`);
            }
          }

          price[`color_scheme_${colorSchemePrice.colorSchemeId}_tooltip`] = tooltip;
        }

        prices.push(price);
      }

      return prices;
    },

    appGroupDiscounts() {
      const discounts = [];

      if (!this.item.discounts) {
        return discounts;
      }

      for (const [appGroupId, appGroupDiscounts] of Object.entries(this.item.appGroupDiscounts)) {
        const appGroup = this.appGroups.find((ag) => ag.id === +appGroupId);

        for (const discount of appGroupDiscounts) {
          const discountInList = discounts.find((d) => d.id === discount.id);

          if (!discountInList) {
            discounts.push({
              id: discount.id,
              name: discount.name,
              appGroups: [appGroup],
            });
          } else if (appGroup && !discountInList.appGroups.find((ag) => ag.id === appGroup.id)) {
            discountInList.appGroups.push(appGroup);
          }
        }
      }

      return discounts;
    },

    checkedAppGroups() {
      return this.item.appGroups.map((item) => item.id);
    },

    allAppGroupChecked() {
      if (!this.item.appGroups) {
        return false;
      }

      const appGroups = this.appGroups.map((item) => item.id);

      return appGroups.every((id) => this.checkedAppGroups.includes(id));
    },

    allDiscountChecked() {
      if (!this.item.discounts) {
        return false;
      }

      const discountsCount = this.appGroupDiscounts.length;
      const checkedDiscountsCount = this.item.discounts.length;

      if (discountsCount === checkedDiscountsCount) {
        return false;
      }

      return !checkedDiscountsCount;
    },
  },
  created() {
    this.fetchPromotions();
    this.fetchGroups();
    this.fetchColorSchemes();
  },
  methods: {
    ...mapActions('dictionaries/promotions', {
      fetchPromotions: 'fetchItems',
    }),
    ...mapActions('dictionaries/appGroups', {
      fetchGroups: 'fetchItems',
    }),
    ...mapActions('dictionaries/colorSchemes', {
      fetchColorSchemes: 'fetchItems',
    }),

    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    isAppGroupChecked(appGroup) {
      if (!this.item.appGroups) {
        return false;
      }

      return !!this.item.appGroups.find((ag) => ag.id === appGroup.id);
    },

    isAppGroupClosingDisabled(appGroup) {
      if (!this.item.ticketsCount) {
        return false;
      }

      if (this.recentlyChangedAppGroupStatus.includes(appGroup.id)) {
        return false;
      }

      if (this.isAppGroupChecked(appGroup)) {
        this.appGroupsDisabled = true;
        return true;
      }

      return false;
    },

    toggleAppGroupAll() {
      if (!this.allAppGroupChecked) {
        this.appGroups.forEach((appGroup) => {
          if (!this.checkedAppGroups.includes(appGroup.id)) {
            this.toggleAppGroup(appGroup);
          }
        });
      } else {
        this.recentlyChangedAppGroupStatus = [];
        this.updateValue('appGroups', []);
      }
    },

    toggleAppGroup(appGroup) {
      this.recentlyChangedAppGroupStatus.push(appGroup.id);

      if (this.isAppGroupChecked(appGroup)) {
        this.updateValue('appGroups', this.item.appGroups.filter((ag) => ag.id !== appGroup.id));
      } else {
        this.updateValue('appGroups', [...this.item.appGroups, appGroup]);
      }
    },

    isDiscountChecked(discount) {
      if (!this.item.discounts) {
        return true;
      }

      return !this.item.discounts.find((d) => d.id === discount.id);
    },

    toggleDiscountAll() {
      if (this.allDiscountChecked) {
        this.getAppGroupDiscounts.filter((discount) => this.toggleDiscount(discount));
      } else {
        this.updateValue('discounts', []);
      }
    },

    toggleDiscount(discount) {
      if (this.isDiscountChecked(discount)) {
        this.updateValue('discounts', [...this.item.discounts, {
          id: discount.id,
          name: discount.name,
        }]);
      } else {
        this.updateValue('discounts', this.item.discounts.filter((d) => d.id !== discount.id));
      }
    },
  },
};
</script>

<style scoped>
.datatable >>> .app-group-name {
  padding-left: 0;
}
.datatable >>> .color-scheme-price {
  width: 20%;
  text-align: center;
}
</style>
